@import "./theme.scss";
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');
.line-break {
    white-space: pre-wrap;
}

h1,
h2 {
    font-family: 'Orbitron', sans-serif;
}

.product-card {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 35px #f2f4f9;
    padding: 30px;
    margin-bottom: 30px;
    h3 {
        font-family: 'Orbitron', sans-serif;
        font-weight: bold;
        >* {
            font-weight: bold;
            font-size: 25px;
        }
    }
    .prices {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    }
    .price-table {
        &>div:first-of-type {
            background-color: $primary-color;
            color: white;
        }
    }
    .price-col {
        &>div {
            padding: 5px 1px;
            border-bottom: 1px solid gray;
            border-right: 1px solid gray;
            &:nth-child(4) {
                margin-top: 14px;
                border-top: 1px solid gray;
            }
        }
        &.first>div {
            border-left: 1px solid gray;
            padding-left: 5px;
        }
        &:not(.first)>div {
            text-align: center;
        }
    }
}

.about-us {
    width: 100%;
    max-width: 800px;
    margin: 40px auto;
    padding: 0px 15px;
}

.single-service {
    transition: box-shadow 0.5s ease-in-out;
    &:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
}

.image-bg {
    background-image: url('../assets/home-bg.jpg');
    background-position: center;
    background-size: cover;
    min-height: 65vh;
    display: flex;
    align-items: center;
}

.navbar-area {
    background-color: #f3f4f9;
}

.sticky .navbar {
    .navbar-nav .nav-item a {
        color: #6B6F92;
    }
    padding: 0;
}

@media (max-width: 767px) {
    .navbar {
        padding: 10px 0;
    }
    .image-bg {
        min-height: 30vh;
    }
    footer.footer {
        padding-top: 20px;
    }
    section.hero-section {
        padding: 100px 0 60px;
        min-height: calc(100vh - 180px);
    }
    .product-card {
        .price-table {
            margin-top: 20px;
        }
        .prices {
            display: block;
            .price-col {
                border: 1px solid gray;
                margin-bottom: 10px;
                &>div {
                    display: flex;
                    justify-content: space-between;
                    border: 0;
                    margin: 0;
                }
            }
        }
        .price-col.first,
        .price-col.second {
            display: none;
        }
    }
    .hero-section {
        .container {
            h1 {
                font-size: 1.75rem;
            }
            .main-btn {
                padding: 10px 20px;
                font-size: 1rem;
            }
        }
        h2 {
            font-size: 1.5rem;
        }
        .section-title p {
            font-size: 1rem;
        }
    }
}