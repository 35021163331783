@import "./bs-scss/bootstrap.scss";
@import "https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap";
$primary-color: #528ac7;
@keyframes "animation1" {
    0% {
        transform: translateY(30px);
    }
    50% {
        transform: translateY(-30px);
    }
    100% {
        transform: translateY(30px);
    }
}

@keyframes "spinner-linspin" {
    to {
        transform: rotate(360deg);
    }
}

@keyframes "spinner-easespin" {
    12.5% {
        transform: rotate(135deg);
    }
    25% {
        transform: rotate(270deg);
    }
    37.5% {
        transform: rotate(405deg);
    }
    50% {
        transform: rotate(540deg);
    }
    62.5% {
        transform: rotate(675deg);
    }
    75% {
        transform: rotate(810deg);
    }
    87.5% {
        transform: rotate(945deg);
    }
    to {
        transform: rotate(1080deg);
    }
}

@keyframes "spinner-left-spin" {
    0% {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    to {
        transform: rotate(130deg);
    }
}

@keyframes "right-spin" {
    0% {
        transform: rotate(-130deg);
    }
    50% {
        transform: rotate(5deg);
    }
    to {
        transform: rotate(-130deg);
    }
}

.counter-up-section {
    .counter-up-content {
        .counter-up-wrapper {
            .single-counter {
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80px;
                    height: 80px;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    color: $primary-color;
                    font-size: 42px;
                }
                margin-bottom: 35px;
                .icon.color-1 {
                    background: #FFEFFF;
                }
                .icon.color-2 {
                    background: #E1F2FB;
                }
                .icon.color-3 {
                    background: #FFEAEA;
                }
                .icon.color-4 {
                    background: #ECE8FF;
                }
                .content {
                    h1 {
                        font-size: 45px;
                        margin-bottom: 10px;
                    }
                    span {
                        font-size: 25px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .counter-up-img {
        img {
            width: 100%;
        }
    }
}

.footer {
    .widget-wrapper {
        .footer-widget {
            .socials {
                li {
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        color: #6B6F92;
                        background: #fff;
                        &:hover {
                            padding-left: 0px;
                        }
                    }
                    margin-right: 20px;
                }
                display: flex;
                align-items: center;
            }
            margin-bottom: 40px;
            .desc {
                font-size: 18px;
                line-height: 28px;
            }
            h3 {
                margin-bottom: 35px;
            }
            ul {
                li {
                    font-size: 18px;
                    line-height: 36px;
                    a {
                        color: #6B6F92;
                        &:hover {
                            color: $primary-color;
                            padding-left: 7px;
                        }
                    }
                }
            }
        }
    }
    background-color: #f2f4f9;
    background-size: cover;
    background-position: top center;
    padding-top: 150px;
    .copy-right {
        text-align: center;
        padding: 30px 0;
        border-top: 2px solid #fff;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #6B6F92;
    overflow-x: hidden;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        text-decoration: none;
    }
    &:hover {
        color: $primary-color;
        text-decoration: none;
    }
    transition: all 0.3s ease-out 0s;
    text-decoration: none;
    display: inline-block;
}

input {
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
}

textarea {
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
}

button {
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
}

.btn {
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    &:not(:disabled) {
        &:not(.disabled).active {
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }
        &:not(.disabled) {
            &:active {
                text-decoration: none;
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.btn.focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}

i {
    display: inline-block;
}

span {
    display: inline-block;
}

audio {
    vertical-align: middle;
}

canvas {
    vertical-align: middle;
}

iframe {
    vertical-align: middle;
}

img {
    vertical-align: middle;
}

svg {
    vertical-align: middle;
}

video {
    vertical-align: middle;
}

h1 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 700;
    margin: 0px;
    color: #313450;
    font-size: 35px;
    a {
        color: inherit;
    }
}

h2 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 700;
    margin: 0px;
    color: #313450;
    font-size: 30px;
    a {
        color: inherit;
    }
}

h3 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 700;
    margin: 0px;
    color: #313450;
    font-size: 25px;
    a {
        color: inherit;
    }
}

h4 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 700;
    margin: 0px;
    color: #313450;
    font-size: 20px;
    a {
        color: inherit;
    }
}

h5 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 700;
    margin: 0px;
    color: #313450;
    font-size: 18px;
    a {
        color: inherit;
    }
}

h6 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 700;
    margin: 0px;
    color: #313450;
    font-size: 16px;
    a {
        color: inherit;
    }
}

.section-title {
    p {
        font-size: 18px;
        line-height: 28px;
    }
}

ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    margin: 0px;
}

.img-bg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.error {
    color: orangered;
}

.success {
    color: green;
}

.main-btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: 20px 40px;
    font-size: 18px;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    z-index: 5;
    transition: all .4s ease-in-out;
    border: none;
    background: $primary-color;
    overflow: hidden;
    &:hover {
        color: #fff;
    }
}

.main-btn.border-btn {
    border: 2px solid $primary-color;
    background: transparent;
    color: #313450;
}

.btn-hover {
    position: relative;
    overflow: hidden;
    &::after {
        content: '';
        position: absolute;
        width: 0%;
        height: 0%;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.05);
        top: 50%;
        left: 50%;
        padding: 50%;
        z-index: -1;
        transition: all 0.3s ease-out 0s;
        transform: translate3d(-50%, -50%, 0) scale(0);
    }
    &:hover {
        &::after {
            transform: translate3d(-50%, -50%, 0) scale(1.3);
        }
    }
}

.scroll-top {
    width: 45px;
    height: 45px;
    background: $primary-color;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #fff;
    border-radius: 5px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9;
    cursor: pointer;
    transition: all 0.3s ease-out 0s;
    &:hover {
        color: #fff;
        background-color: lighten($primary-color, 10%);
    }
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    display: table;
    height: 100%;
    width: 100%;
    background: #fff;
    z-index: 99999;
    .loader {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        .spinner {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 64px;
            margin-left: -32px;
            z-index: 18;
            pointer-events: none;
            .spinner-container {
                pointer-events: none;
                position: absolute;
                width: 100%;
                padding-bottom: 100%;
                top: 50%;
                left: 50%;
                margin-top: -50%;
                margin-left: -50%;
                animation: spinner-linspin 1568.2353ms linear infinite;
                .spinner-rotator {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    animation: spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
                    .spinner-left {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        overflow: hidden;
                        right: 50%;
                    }
                    .spinner-right {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        overflow: hidden;
                        left: 50%;
                    }
                }
            }
        }
        .spinner-circle {
            box-sizing: border-box;
            position: absolute;
            width: 200%;
            height: 100%;
            border-style: solid;
            border-color: $primary-color $primary-color #ddd;
            border-radius: 50%;
            border-width: 6px;
        }
        .spinner-left {
            .spinner-circle {
                left: 0;
                right: -100%;
                border-right-color: #ddd;
                animation: spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            }
        }
        .spinner-right {
            .spinner-circle {
                left: -100%;
                right: 0;
                border-left-color: #ddd;
                animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            }
        }
    }
}

.navbar-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: all 0.3s ease-out 0s;
}

.sticky {
    position: fixed;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-out 0s;
    .navbar {
        padding: 17px 0;
        .navbar-nav {
            .nav-item {
                a {
                    color: #313450;
                }
            }
        }
        .navbar-toggler {
            .toggler-icon {
                background: #313450;
            }
        }
    }
}

.navbar {
    padding: 0;
    border-radius: 5px;
    position: relative;
    transition: all 0.3s ease-out 0s;
    padding: 20px 0;
}

.navbar-toggler {
    padding: 0;
    &:focus {
        outline: none;
        box-shadow: none;
    }
    .toggler-icon {
        width: 30px;
        height: 2px;
        background-color: #313450;
        display: block;
        margin: 5px 0;
        position: relative;
        transition: all 0.3s ease-out 0s;
    }
}

.navbar-toggler.active {
    .toggler-icon {
        &:nth-of-type(1) {
            transform: rotate(45deg);
            top: 7px;
        }
        &:nth-of-type(2) {
            opacity: 0;
        }
        &:nth-of-type(3) {
            transform: rotate(135deg);
            top: -7px;
        }
    }
}

.navbar-nav {
    .nav-item {
        position: relative;
        margin-left: 40px;
        a {
            font-size: 18px;
            font-weight: 400;
            color: #6B6F92;
            transition: all 0.3s ease-out 0s;
            padding: 10px 0;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                left: auto;
                width: 0;
                height: 2px;
                background: $primary-color;
                z-index: -1;
                opacity: 0;
                border-radius: 10px;
                transition: all 0.3s ease-out 0s;
            }
            &:hover {
                color: $primary-color;
                &::before {
                    right: auto;
                    left: 0;
                    opacity: 1;
                    width: 100%;
                }
            }
        }
        a.active {
            color: $primary-color;
            &::before {
                right: auto;
                left: 0;
                opacity: 1;
                width: 100%;
            }
        }
        &:hover {
            .sub-menu {
                top: 100%;
                opacity: 1;
                visibility: visible;
            }
        }
        .sub-menu {
            width: 200px;
            background-color: #fff;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
            position: absolute;
            top: 110%;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-out 0s;
            li {
                display: block;
                a {
                    display: block;
                    padding: 8px 20px;
                    color: #222;
                    &:hover {
                        padding-left: 25px;
                        color: $primary-color;
                    }
                }
                a.active {
                    padding-left: 25px;
                    color: $primary-color;
                }
            }
        }
    }
    .sub-nav-toggler {
        display: none;
        span {
            width: 8px;
            height: 8px;
            border-left: 1px solid #222;
            border-bottom: 1px solid #222;
            transform: rotate(-45deg);
            position: relative;
            top: -5px;
        }
    }
}

.hero-section {
    position: relative;
    z-index: 1;
    padding: 120px 0 120px;
    min-height: calc(100vh - 110px);
    background-image: url("../assets/hero-bg.jpg");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
    .hero-content {
        span {
            font-family: "Archivo Black", sans-serif;
            font-size: 30px;
            color: $primary-color;
            margin-bottom: 20px;
        }
        h1 {
            font-size: 50px;
            margin-bottom: 35px;
        }
        p {
            margin-bottom: 40px;
        }
    }
    .hero-shape {
        .shape {
            position: absolute;
            z-index: -1;
        }
        .shape.shape-1 {
            top: -285px;
            right: -300px;
        }
    }
}

.client-logo-section {
    .single-logo {
        opacity: .43;
        transition: all 0.3s ease-out 0s;
        &:hover {
            opacity: 1;
        }
        img {
            max-width: 100%;
        }
    }
}

.about-section {
    .about-img {
        img {
            width: 100%;
        }
    }
    .accordion {
        .single-faq {
            margin-bottom: 20px;
            border-radius: 10px;
            background: #fff;
            box-shadow: 0px 0px 35px #f2f4f9;
            button {
                padding: 20px 30px 15px;
                border: none;
                background: none;
                font-size: 20px;
                font-weight: 500;
                color: #313450;
                position: relative;
                &::after {
                    content: "\eb2c";
                    font: normal normal normal 1em/1 "LineIcons";
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.3s ease-out 0s;
                }
            }
            button.collapsed {
                &::after {
                    content: "\eb53";
                }
            }
            .faq-content {
                padding: 0 30px;
                padding-bottom: 20px;
            }
        }
    }
}

.service-section {
    .single-service {
        text-align: center;
        padding: 20px 10px;
        border-radius: 10px;
        background: #fff;
        margin-bottom: 30px;
        &:hover {
            .icon {
                color: #fff;
            }
            .icon.color-1 {
                background: #FB32FB;
            }
            .icon.color-2 {
                background: $primary-color;
            }
            .icon.color-3 {
                background: #FF3B3B;
            }
            .icon.color-4 {
                background: #5A37FF;
            }
        }
        .icon {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            margin-bottom: 30px;
            font-size: 40px;
            color: #313450;
            transition: all 0.3s ease-out 0s;
        }
        .icon.color-1 {
            background: #FFEFFF;
        }
        .icon.color-2 {
            background: #E4E9FF;
        }
        .icon.color-3 {
            background: #FFEAEA;
        }
        .icon.color-4 {
            background: #ECE8FF;
        }
        .content {
            h3 {
                font-size: 24px;
                margin-bottom: 15px;
            }
        }
    }
}

@mixin margin-padding-list {
    @for $i from 1 through 45 {
        .mt-#{$i * 5} {
            margin-top: #{$i * 5}px;
        }
        .mb-#{$i * 5} {
            margin-bottom: #{$i * 5}px;
        }
        .pt-#{$i * 5} {
            padding-top: #{$i * 5}px;
        }
        .pb-#{$i * 5} {
            padding-bottom: #{$i * 5}px;
        }
    }
}

@include margin-padding-list();
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sticky {
        .navbar {
            padding: 15px 0;
        }
    }
    .navbar {
        padding: 17px 0;
    }
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 12px;
    }
    .navbar-nav {
        .nav-item {
            margin-left: 20px;
            a {
                display: inline-block;
                padding: 6px 0px;
                color: #6B6F92;
            }
            &:hover {
                .sub-menu {
                    top: 0;
                }
            }
            .sub-menu {
                position: relative;
                width: 100%;
                top: 0;
                display: none;
                opacity: 1;
                visibility: visible;
            }
        }
        .sub-nav-toggler {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            background: none;
            color: #222;
            font-size: 18px;
            border: 0;
            width: 30px;
            height: 30px;
        }
    }
    .hero-section {
        .hero-content {
            margin-bottom: 50px;
        }
        .hero-img {
            img {
                width: 100%;
            }
        }
        .hero-shape {
            .shape.shape-1 {
                display: none;
            }
        }
    }
}

@media (max-width: 767px) {
    .sticky {
        .navbar {
            padding: 15px 0;
        }
    }
    .navbar {
        padding: 17px 0;
    }
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 12px;
    }
    .navbar-nav {
        .nav-item {
            margin-left: 20px;
            a {
                display: inline-block;
                padding: 6px 0px;
                color: #6B6F92;
            }
            &:hover {
                .sub-menu {
                    top: 0;
                }
            }
            .sub-menu {
                position: relative;
                width: 100%;
                top: 0;
                display: none;
                opacity: 1;
                visibility: visible;
            }
        }
        .sub-nav-toggler {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            background: none;
            color: #222;
            font-size: 18px;
            border: 0;
            width: 30px;
            height: 30px;
        }
    }
    .hero-section {
        .hero-content {
            margin-bottom: 50px;
            h1 {
                font-size: 36px;
            }
        }
        .hero-img {
            img {
                width: 100%;
            }
        }
        .hero-shape {
            .shape.shape-1 {
                display: none;
            }
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-section {
        padding: 180px 0 140px;
        .hero-content {
            h1 {
                font-size: 45px;
            }
        }
        .hero-img {
            img {
                width: 100%;
            }
        }
        .hero-shape {
            .shape.shape-1 {
                top: -420px;
                right: -550px;
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-section {
        padding: 180px 0 140px;
        .hero-content {
            span {
                font-size: 25px;
            }
            h1 {
                font-size: 37px;
            }
        }
        .hero-img {
            img {
                width: 100%;
            }
        }
        .hero-shape {
            .shape.shape-1 {
                top: -400px;
                right: -550px;
            }
        }
    }
    .counter-up-section {
        .counter-up-content {
            .counter-up-wrapper {
                .single-counter {
                    .content {
                        h1 {
                            font-size: 38px;
                        }
                        span {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .hero-section {
        .hero-content {
            h1 {
                font-size: 45px;
            }
        }
    }
}

@media only screen and (min-width: 1400px) {
    .counter-up-section {
        .counter-up-content {
            margin-right: 65px;
        }
    }
}